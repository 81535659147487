import styles from './Services.module.css'
import ComputerVisionMini from '../../../Images/services/ComputerVisionMini.webp';
import voiceMini from '../../../Images/services/voiceMini.webp';
import educationMini from '../../../Images/services/educationMini.webp';
import AImini from '../../../Images/services/AImini.webp';
import Partners from './Partners/Partners';

function Services(props) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={styles.ServicesWrapper}>
                <div className={styles.headerText}>
                    Наш сервис - наши услуги
                </div>
                <div onClick={(evt) => {
                    evt.preventDefault();
                    setTimeout(() => {
                        props.ClientsRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
                    }, 100);
                }}>
                    <div className={styles.blocksWrapper}>
                        <div className={styles.upBoxWrapper}>
                            <div className={styles.ComputerVisionWrapper}>
                                <div className={styles.ComputerVisionText}>
                                    Разработка проектов по компьютерному зрению
                                </div>
                            </div>
                            <div className={styles.miniBoxWrapper}>
                                <div className={styles.VoiceWrapper}>
                                    <div className={styles.VoiceText}>
                                        Разработка модулей распознавания речи
                                    </div>
                                </div>
                                <div className={styles.EducationWrapper}>
                                    <div className={styles.EducationText}>
                                    Разработка образова-тельных программ
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.AIwrapper}>
                            <div className={styles.AIText}>
                            Заказная разработка по направлениям искусственного интеллекта
                            </div>
                        </div>
                    </div>
                    <div className={styles.BlocksWrapperForMobile}>
                        <img style={{cursor:'pointer'}} src={ComputerVisionMini} />
                        <img style={{cursor:'pointer'}} src={voiceMini} />
                        <img style={{cursor:'pointer'}} src={educationMini} />
                        <img style={{cursor:'pointer'}} src={AImini} />
                    </div>
                </div>
                <div>
                    <Partners />
                </div>

            </div>
        </div>
    );
}


export default Services;
