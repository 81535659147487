import styles from './DownloaderFiles.module.css'
import { useState } from "react";

function DownloaderFiles() {
    const [email, setEmail] = useState("");
    const onKeyDown = e => {
        if (e.key === 'Enter')
            sendMail()
    }

    const sendMail = () => {
        alert("Загрузка файла")

    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 200 }}>
            <div className={styles.ClientsWrapper}>
                <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                    <div className={styles.feedbackWrapper} style={{ marginTop: 20 }}>
                        <div className={styles.smallText}>
                            Робот базируется на облачной платформе поддержки смешанного режима преподавания и развивающего обучения в дошкольных учреждениях и начальной школе с применением <span style={{ color: '#4A6FF5' }}>подходов искусственного интеллекта</span> и представляет её автономную версию, способную работать без интернета, обрабатывая все необходимые параметры внутри себя.
                        </div>
                        <div className={styles.spareElement}>
                            <svg width="30" height="100%" viewBox="0 0 30 100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 25.9297L29.4338 0.929688L0.56625 0.929687L12.5 25.9297L17.5 25.9297ZM12.5 165.999C12.5 167.38 13.6193 168.499 15 168.499C16.3807 168.499 17.5 167.38 17.5 165.999L12.5 165.999ZM12.5 23.4297L12.5 165.999L17.5 165.999L17.5 23.4297L12.5 23.4297Z" fill="#4A6FF5" />
                            </svg>
                        </div>
                        <div className={styles.sendFormWrapper}>
                            <div className={styles.downloaderText}>
                                Презентация проекта облачной платформы речевого развития "РобоЛого"
                            </div>
                            {/* + '/t0zteuzMEWxKgsY40726cF3KXqO25EyKmDLoHwxvzfVTuxq2eYSkkz7PRnKskdje.pdf' */}
                            <a href={window.location.href.split(window.location.pathname)[0] + '/meow.pdf'}>
                                <div className={styles.buttonSendMail} style={{ marginTop: 23 }} >
                                    Скачать
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default DownloaderFiles;
